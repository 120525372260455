import type {
  IAnalyticsLogin,
  IAnalyticsSearch,
  IAnalyticsProduct,
  IAnalyticsProductTile,
  IAnalyticsItemProduct,
} from '@types';

export const useAnalytics = () => {
  const { store } = storeToRefs(useMainStore());
  const { cart } = storeToRefs(useCartStore());
  const { order } = storeToRefs(useOrderStore());
  const { viewedProducts } = storeToRefs(useAnalyticsStore());
  const { t: $t } = useI18n();

  /**
   * Computes cart data for analytics events.
   * @returns {Object} Cart data with items, total value, and currency.
   */
  const cartData = computed(() => {
    const items = [];

    if (cart.value?.items?.length) {
      items.push(
        ...cart.value?.items?.map((item: any) => {
          const attributes = item?.product?.attributes?.items ?? item?.product?.attributes ?? [];
          const brand = attributes?.find((item: any) => item.code === 'publisher')?.selectedOptions?.[0]?.label;
          const author = attributes?.find((item: any) => item.code === 'author')?.selectedOptions?.[0]?.label;

          return {
            item_id: item?.product?.sku,
            item_name: item?.product?.name,
            item_brand: brand,
            item_author: author,
            price: item?.prices?.price,
            quantity: item?.quantity,
          };
        })
      );
    }

    if (cart.value?.itemsKit?.length) {
      cart.value?.itemsKit?.forEach((itemKit: any) => {
        items.push(
          ...itemKit?.items?.map((item: any) => {
            const attributes = item?.product?.attributes?.items ?? item?.product?.attributes ?? [];
            const brand = attributes?.find((item: any) => item.code === 'publisher')?.selectedOptions?.[0]?.label;
            const author = attributes?.find((item: any) => item.code === 'author')?.selectedOptions?.[0]?.label;

            return {
              item_list_name: $t('sliders.cheaperTogether'),
              item_list_id: itemKit?.kitId,
              item_id: item?.product?.sku,
              item_name: item?.product?.name,
              item_brand: brand,
              item_author: author,
              price: item?.prices?.price,
              quantity: item?.quantity,
            };
          })
        );
      });
    }

    return {
      currency: store.value?.currencyCode,
      value: cart.value?.prices?.total,
      items,
    };
  });

  /**
   * Computes order data for analytics events.
   * @returns {Object} Order data with items, total value, currency, and transaction details.
   */
  const orderData = computed(() => {
    const items = [];

    if (order.value?.items?.length) {
      items.push(
        ...order.value?.items?.map((item: any) => {
          const attributes = item?.attributes?.items ?? item?.attributes ?? [];
          const brand = attributes?.find((item: any) => item.code === 'publisher')?.selectedOptions?.[0]?.label;
          const author = attributes?.find((item: any) => item.code === 'author')?.selectedOptions?.[0]?.label;
          const language = attributes?.find((item: any) => item.code === 'language')?.selectedOptions?.[0]?.label;
          const format = attributes?.find((item: any) => item.code === 'format')?.selectedOptions?.[0]?.label;

          return {
            item_id: item?.sku,
            item_name: item?.name,
            item_brand: brand,
            item_author: author,
            item_language: language,
            item_variant: format,
            price: item?.prices?.price,
            discount: item?.prices?.discount,
            quantity: item?.quantity,
          };
        })
      );
    }

    if (order.value?.itemsKit?.length) {
      items.push(
        ...order.value?.itemsKit?.map((itemKit: any) => {
          return itemKit?.items?.map((item: any) => {
            const attributes = item?.attributes?.items ?? item?.attributes ?? [];
            const brand = attributes?.find((item: any) => item.code === 'publisher')?.selectedOptions?.[0]?.label;
            const author = attributes?.find((item: any) => item.code === 'author')?.selectedOptions?.[0]?.label;
            const language = attributes?.find((item: any) => item.code === 'language')?.selectedOptions?.[0]?.label;
            const format = attributes?.find((item: any) => item.code === 'format')?.selectedOptions?.[0]?.label;

            return {
              item_list_name: $t('sliders.cheaperTogether'),
              item_list_id: itemKit?.kitId,
              item_id: item?.sku,
              item_name: item?.name,
              item_brand: brand,
              item_author: author,
              item_language: language,
              item_variant: format,
              price: item?.prices?.price,
              discount: item?.prices?.discount,
              quantity: item?.quantity,
            };
          });
        })
      );
    }

    return {
      currency: store.value?.currencyCode,
      value: order.value?.prices?.total,
      transaction_id: order.value?.number,
      shipping: order.value?.prices?.shipping,
      items,
    };
  });

  /**
   * Tracks login analytics event.
   * @param {IAnalyticsLogin} params - Login event parameters.
   */
  const trackLogin = (params: IAnalyticsLogin): void => {
    if (!store.value?.isGtag) {
      return;
    }

    const data = {
      method: params.method,
    } as any;

    useTrackEvent('login', data);
  };

  /**
   * Tracks viewed product page separate by lists.
   * @param {IAnalyticsProduct} params
   */
  const trackViewedProductPage = ({ breadcrumbs, ...product }: IAnalyticsProduct): void => {
    if (!store.value?.isGtag) {
      return;
    }

    const data = {
      currency: store.value?.currencyCode,
      value: product.price,
      items: [product],
    } as any;

    if (breadcrumbs?.length) {
      breadcrumbs.forEach((breadcrumb, index) => {
        data.items[0][`item_category${index > 0 ? index + 1 : ''}`] = breadcrumb;
      });
    }

    useTrackEvent('view_item', data);
  };

  /**
   * Send data for track viewed products separate by lists.
   * @param {IAnalyticsProduct} params
   */
  const sendViewedProductTile = (): void => {
    viewedProducts.value?.forEach(({ items: listItems, ...list }: any) => {
      const items = listItems.filter((item: any) => !item.isViewed);

      if (items?.length) {
        useTrackEvent('view_item_list', {
          ...list,
          items,
        });

        items.forEach((item: any) => {
          item.isViewed = true;
        });
      }
    });
  };

  let timeoutViewedProducts: any = null;

  /**
   * Tracks viewed products separate by lists.
   * @param {IAnalyticsProduct} params
   */
  const trackViewedProductTile = (params: IAnalyticsProductTile): void => {
    if (!store.value?.isGtag) {
      return;
    }

    if (!viewedProducts.value?.length) {
      viewedProducts.value = [
        {
          items: [],
        },
      ];
    }

    if (
      params.itemListName &&
      !viewedProducts.value?.find((list: any) =>
        params.itemListId
          ? list.item_list_id === params.itemListId && list.item_list_name === params.itemListName
          : list.item_list_name === params.itemListName
      )
    ) {
      viewedProducts.value.push({ item_list_name: params.itemListName, item_list_id: params?.itemListId, items: [] });
    }

    const list = params?.itemListName
      ? viewedProducts.value?.find((list: any) =>
          params.itemListId
            ? list.item_list_id === params.itemListId && list.item_list_name === params.itemListName
            : list.item_list_name === params.itemListName
        )
      : viewedProducts.value[0];

    if (params?.itemListId) {
      list.item_list_id = params?.itemListId;
    }

    if (list?.items && !list?.items?.find((item: any) => item.item_id === params.product?.item_id)) {
      list.items.push({
        item_id: params.product?.item_id,
        item_name: params.product?.item_name,
        item_brand: params.product?.item_brand,
        price: params.product?.price,
      });
    }

    clearTimeout(timeoutViewedProducts);

    timeoutViewedProducts = setTimeout(sendViewedProductTile, 1000);
  };

  const trackSelectedProductTile = (params: IAnalyticsProductTile): void => {
    if (!store.value?.isGtag) {
      return;
    }

    const data = {
      item_list_name: params.itemListName,
      item_list_id: params.itemListId,
      items: [params.product],
    } as any;

    useTrackEvent('select_item', data);
  };

  const trackViewedCart = (): void => {
    useTrackEvent('view_cart', cartData.value);
  };

  const trackAddToCart = (params: IAnalyticsItemProduct): void => {
    if (!store.value?.isGtag) {
      return;
    }

    const data = {
      currency: store.value?.currencyCode,
      value: params.value,
      items: params.items,
    } as any;

    useTrackEvent('add_to_cart', data);
  };

  const trackRemoveFromCart = (params: IAnalyticsItemProduct): void => {
    if (!store.value?.isGtag) {
      return;
    }

    const data = {
      currency: store.value?.currencyCode,
      value: params.value,
      items: params.items,
    } as any;

    useTrackEvent('remove_from_cart', data);
  };

  const trackSearch = (params: IAnalyticsSearch): void => {
    if (!store.value?.isGtag) {
      return;
    }

    const data = {
      search_term: params?.searchPhrase,
    };

    useTrackEvent('search', data);
  };

  const trackAddToWishlist = (params: IAnalyticsItemProduct): void => {
    if (!store.value?.isGtag) {
      return;
    }

    const data = {
      currency: store.value?.currencyCode,
      value: params.value,
      items: params.items,
    } as any;

    useTrackEvent('add_to_wishlist', data);
  };

  const trackCheckout = (): void => {
    if (!store.value?.isGtag) {
      return;
    }

    useTrackEvent('begin_checkout', cartData.value);
  };

  const trackCheckoutShipping = ({ shippingTier }: { shippingTier: string }): void => {
    if (!store.value?.isGtag) {
      return;
    }

    useTrackEvent('add_shipping_info', {
      ...cartData.value,
      shipping_tier: shippingTier,
    });
  };

  const trackCheckoutPayment = ({ paymentType }: { paymentType: string }): void => {
    if (!store.value?.isGtag) {
      return;
    }

    useTrackEvent('add_payment_info', {
      ...cartData.value,
      payment_type: paymentType,
    });
  };

  const trackCheckoutOrder = (): void => {
    if (!store.value?.isGtag) {
      return;
    }

    useTrackEvent('purchase', orderData.value);
  };

  return {
    trackLogin,
    trackViewedProductPage,
    trackViewedProductTile,
    trackSelectedProductTile,
    trackAddToCart,
    trackRemoveFromCart,
    trackViewedCart,
    trackSearch,
    trackAddToWishlist,
    trackCheckout,
    trackCheckoutShipping,
    trackCheckoutPayment,
    trackCheckoutOrder,
  };
};
